export const VIR_DAILY_PHOTOS = {
  FRONT_OF_VAN: 'VIR_DAILY_FRONT_OF_VAN',
  PASSENGER_SIDE_OF_VAN: 'VIR_DAILY_PASSENGER_SIDE_OF_VAN',
  REAR_OF_VAN: 'VIR_DAILY_REAR_OF_VAN',
  DRIVER_SIDE_OF_VAN: 'VIR_DAILY_DRIVER_SIDE_OF_VAN',
  VALIDATOR_RESULT: 'VIR_DAILY_VALIDATOR_RESULT',
}
export const VIR_WEEKLY_PHOTOS = {
  FRONT_LICENSE_PLATE: 'VIR_WEEKLY_FRONT_LICENSE_PLATE',
  PASSENGER_SIDE_WIPER: 'VIR_WEEKLY_PASSENGER_SIDE_WIPER',
  PASSENGER_SIDE_MIRROR: 'VIR_WEEKLY_PASSENGER_SIDE_MIRROR',
  PASSENGER_SIDE_INTERIOR: 'VIR_WEEKLY_PASSENGER_SIDE_INTERIOR',
  DRIVER_SIDE_WIPER: 'VIR_WEEKLY_DRIVER_SIDE_WIPER',
  DRIVER_SIDE_MIRROR: 'VIR_WEEKLY_DRIVER_SIDE_MIRROR',
  DRIVER_SIDE_INTERIOR: 'VIR_WEEKLY_DRIVER_SIDE_INTERIOR',
  PASSENGER_VAN_EXTERIOR: 'VIR_WEEKLY_PASSENGER_VAN_EXTERIOR',
  VAN_INTERIOR_WORK_AREA: 'VIR_WEEKLY_VAN_INTERIOR_WORK_AREA',
  FIRE_EXTINGUISHER_DATE: 'VIR_WEEKLY_FIRE_EXTINGUISHER_DATE',
  FIRE_EXTINGUISHER_GAUGE: 'VIR_WEEKLY_FIRE_EXTINGUISHER_GAUGE',
  SERIAL_NUMBER_CHEETAH: 'VIR_WEEKLY_SERIAL_NUMBER_CHEETAH',
  MANUFACTURE_DATE_CHEETAH: 'VIR_WEEKLY_MANUFACTURE_DATE_CHEETAH',
  BARTEC_SERIAL_NUMBER: 'VIR_WEEKLY_BARTEC_SERIAL_NUMBER',
  TORQUE_WRENCH_WINDOW: 'VIR_WEEKLY_TORQUE_WRENCH_WINDOW',
  BALANCING_CUP_CONDITION: 'VIR_WEEKLY_BALANCING_CUP_CONDITION',
  PASSENGER_FRONT_TIRE_WHEEL_ASSEMBLY:
    'VIR_WEEKLY_PASSENGER_FRONT_TIRE_WHEEL_ASSEMBLY',
  PASSENGER_FRONT_TREAD_PHOTO: 'VIR_WEEKLY_PASSENGER_FRONT_TREAD_PHOTO',
  PASSENGER_REAR_TIRE_WHEEL_ASSEMBLY:
    'VIR_WEEKLY_PASSENGER_REAR__TIRE_WHEEL_ASSEMBLY',
  PASSENGER_REAR_TREAD_PHOTO: 'VIR_WEEKLY_PASSENGER_REAR_TREAD_PHOTO',
  REAR_LICENSE_PLATE: 'VIR_WEEKLY_REAR_LICENSE_PLATE',
  REAR_INTERIOR_CARGO_AREA: 'VIR_WEEKLY_REAR_INTERIOR_CARGO_AREA',
  DRIVER_FRONT_TIRE_WHEEL_ASSEMBLY:
    'VIR_WEEKLY_DRIVER_FRONT_TIRE_WHEEL_ASSEMBLY',
  DRIVER_FRONT_TREAD_PHOTO: 'VIR_WEEKLY_DRIVER_FRONT_TREAD_PHOTO',
  DRIVER_REAR_TIRE_WHEEL_ASSEMBLY: 'VIR_WEEKLY_DRIVER_REAR_TIRE_WHEEL_ASSEMBLY',
  DRIVER_REAR_TREAD_PHOTO: 'VIR_WEEKLY_DRIVER_REAR_TREAD_PHOTO',
  DRIVER_VAN_EXTERIOR: 'VIR_WEEKLY_DRIVER_VAN_EXTERIOR',
  TREAD_WORK_SHOE: 'VIR_WEEKLY_TREAD_WORK_SHOE',
  TOLL_TRANSPONDER_SERIAL: 'VIR_WEEKLY_TOLL_TRANSPONDER_SERIAL',
  DASHBOARD: 'VIR_WEEKLY_DASHBOARD',
  REMAINING_OIL_LIFE: 'VIR_WEEKLY_REMAINING_OIL_LIFE',
  VAN_REGISTRATION: 'VIR_WEEKLY_VAN_REGISTRATION',
  INSURANCE_CARD: 'VIR_WEEKLY_INSURANCE_CARD',
  GAS_CARD_NUMBER: 'VIR_WEEKLY_GAS_CARD_NUMBER',
  FIRST_AID_KIT: 'VIR_WEEKLY_FIRST_AID_KIT',
  ANSI_OSHA_WARNING_TRIANGLES: 'VIR_WEEKLY_ANSI_OSHA_WARNING_TRIANGLES',
}
export const VIR_DEFECT_FRONT = {
  FRONT_LIGHT_COVERS_ISSUES: 'VIR_DEFECT_FRONT_LIGHT_COVERS_ISSUES',
  FRONT_HEADLIGHT_NOT_WORKING: 'VIR_DEFECT_FRONT_HEADLIGHT_NOT_WORKING',
  FRONT_HAZARD_LIGHT_NOT_WORKING: 'VIR_DEFECT_FRONT_HAZARD_LIGHT_NOT_WORKING',
  FRONT_WIPERS_DAMAGED: 'VIR_DEFECT_FRONT_WIPERS_DAMAGED',
  FRONT_LOOSE_HANGING_OBJECTS_UNDERNEATH:
    'VIR_DEFECT_FRONT_LOOSE_HANGING_OBJECTS_UNDERNEATH',
  FRONT_NOTICEABLE_LEANING: 'VIR_DEFECT_FRONT_NOTICEABLE_LEANING',
}
export const VIR_DEFECT_REAR = {
  REAR_LIGHT_COVERS_ISSUES: 'VIR_DEFECT_REAR_LIGHT_COVERS_ISSUES',
  REAR_REARVIEW_CAMERA_ISSUES: 'VIR_DEFECT_REAR_REARVIEW_CAMERA_ISSUES',
  REAR_HEADLIGHT_NOT_WORKING: 'VIR_DEFECT_REAR_HEADLIGHT_NOT_WORKING',
  REAR_HAZARD_LIGHT_NOT_WORKING: 'VIR_DEFECT_REAR_HAZARD_LIGHT_NOT_WORKING',
  REAR_LICENSE_PLATE_LIGHT_NOT_WORKING:
    'VIR_DEFECT_REAR_LICENSE_PLATE_LIGHT_NOT_WORKING',
  REAR_DOORS_FUNCTION_ISSUE: 'VIR_DEFECT_REAR_DOORS_FUNCTION_ISSUE',
  REAR_DOOR_SAFETY_LOCK_BROKEN: 'VIR_DEFECT_REAR_DOOR_SAFETY_LOCK_BROKEN',
  REAR_LOOSE_HANGING_OBJECTS_UNDERNEATH:
    'VIR_DEFECT_REAR_LOOSE_HANGING_OBJECTS_UNDERNEATH',
  REAR_NOTICEABLE_LEANING: 'VIR_DEFECT_REAR_NOTICEABLE_LEANING',
}
export const VIR_DEFECT_PASSENGER = {
  PASSENGER_MIRROR_ISSUES: 'VIR_DEFECT_PASSENGER_MIRROR_ISSUES',
  PASSENGER_MIRROR_ADJUSTMENT_ISSUE:
    'VIR_DEFECT_PASSENGER_MIRROR_ADJUSTMENT_ISSUE',
  PASSENGER_MIRROR_HAZARD_INDICATOR_ISSUES:
    'VIR_DEFECT_PASSENGER_MIRROR_HAZARD_INDICATOR_ISSUES',
  PASSENGER_MIRROR_HAZARD_INDICATOR_NOT_WORKING:
    'VIR_DEFECT_PASSENGER_MIRROR_HAZARD_INDICATOR_NOT_WORKING',
  PASSENGER_DOT_NUMBER_MISSING: 'VIR_DEFECT_PASSENGER_DOT_NUMBER_MISSING',
  PASSENGER_SIDE_STEP_ISSUES: 'VIR_DEFECT_PASSENGER_SIDE_STEP_ISSUES',
  PASSENGER_BODY_DAMAGE: 'VIR_DEFECT_PASSENGER_BODY_DAMAGE_MORE_THAN_3INCHES',
  PASSENGER_SHARP_EDGES_HOLES: 'VIR_DEFECT_PASSENGER_SHARP_EDGES_HOLES',
  PASSENGER_LOOSE_BODY_PARTS: 'VIR_DEFECT_PASSENGER_LOOSE_BODY_PARTS',
  PASSENGER_DOOR_FUNCTION_ISSUE: 'VIR_DEFECT_PASSENGER_DOOR_FUNCTION_ISSUE',
  PASSENGER_DOOR_LOCK_ISSUE: 'VIR_DEFECT_PASSENGER_DOOR_LOCK_ISSUE',
  PASSENGER_LOOSE_HANGING_OBJECTS_UNDERNEATH:
    'VIR_DEFECT_PASSENGER_LOOSE_HANGING_OBJECTS_UNDERNEATH',
  PASSENGER_NOTICEABLE_LEANING: 'VIR_DEFECT_PASSENGER_NOTICEABLE_LEANING',
  PASSENGER_FIRE_EXTINGUISHER_ISSUES:
    'VIR_DEFECT_PASSENGER_FIRE_EXTINGUISHER_ISSUES',
  PASSENGER_BATTERY_CHARGE_1_LOW: 'VIR_DEFECT_PASSENGER_BATTERY_CHARGE_1_LOW',
  PASSENGER_BATTERY_CHARGE_2_LOW: 'VIR_DEFECT_PASSENGER_BATTERY_CHARGE_2_LOW',
  PASSENGER_WHEEL_CHOCKS_ISSUE: 'VIR_DEFECT_PASSENGER_WHEEL_CHOCKS_ISSUE',
  PASSENGER_JACK_PUCKS_ISSUE: 'VIR_DEFECT_PASSENGER_JACK_PUCKS_ISSUE',
  PASSENGER_BARTEC_TPMS_TOOL_ISSUE:
    'VIR_DEFECT_PASSENGER_BARTEC_TPMS_TOOL_ISSUE',
  PASSENGER_CHEETAH_AIR_GAUGE_ISSUE:
    'VIR_DEFECT_PASSENGER_CHEETAH_AIR_GAUGE_ISSUE',
  PASSENGER_MOUNTER_TULIP_ISSUE: 'VIR_DEFECT_PASSENGER_MOUNTER_TULIP_ISSUE',
  PASSENGER_BALANCER_ISSUE: 'VIR_DEFECT_PASSENGER_BALANCER_ISSUE',
  PASSENGER_FLUID_LEAK: 'VIR_DEFECT_PASSENGER_FLUID_LEAK',
  PASSENGER_FUEL_CAP_ISSUE: 'VIR_DEFECT_PASSENGER_FUEL_CAP_ISSUE',
  PASSENGER_FRONT_FLAT_BUBBLING_BULGING_SWELLING:
    'VIR_DEFECT_PASSENGER_FRONT_FLAT_BUBBLING_BULGING_SWELLING',
  PASSENGER_FRONT_OBJECT_CUTS_DENTS_EXPOSED_WIRES:
    'VIR_DEFECT_PASSENGER_FRONT_OBJECT_CUTS_DENTS_EXPOSED_WIRES',
  PASSENGER_FRONT_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE:
    'VIR_DEFECT_PASSENGER_FRONT_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE',
  PASSENGER_FRONT_WHEEL_NUTS_ISSUE:
    'VIR_DEFECT_PASSENGER_FRONT_WHEEL_NUTS_ISSUE',
  PASSENGER_FRONT_UNEVEN_WEAR_ON_TREADS:
    'VIR_DEFECT_PASSENGER_FRONT_UNEVEN_WEAR_ON_TREADS',
  PASSENGER_FRONT_INSUFFICIENT_TREAD:
    'VIR_DEFECT_PASSENGER_FRONT_INSUFFICIENT_TREAD',
  PASSENGER_REAR_FLAT_BUBBLING_BULGING_SWELLING:
    'VIR_DEFECT_PASSENGER_REAR_FLAT_BUBBLING_BULGING_SWELLING',
  PASSENGER_REAR_OBJECT_CUTS_DENTS_EXPOSED_WIRES:
    'VIR_DEFECT_PASSENGER_REAR_OBJECT_CUTS_DENTS_EXPOSED_WIRES',
  PASSENGER_REAR_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE:
    'VIR_DEFECT_PASSENGER_REAR_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE',
  PASSENGER_REAR_WHEEL_NUTS_ISSUE: 'VIR_DEFECT_PASSENGER_REAR_WHEEL_NUTS_ISSUE',
  PASSENGER_REAR_UNEVEN_WEAR_ON_TREADS:
    'VIR_DEFECT_PASSENGER_REAR_UNEVEN_WEAR_ON_TREADS',
  PASSENGER_REAR_INSUFFICIENT_TREAD:
    'VIR_DEFECT_PASSENGER_REAR_INSUFFICIENT_TREAD',
}
export const VIR_DEFECT_DRIVER = {
  DRIVER_FLUID_LEAK: 'VIR_DEFECT_DRIVER_FLUID_LEAK',
  DRIVER_FUEL_CAP_ISSUE: 'VIR_DEFECT_DRIVER_FUEL_CAP_ISSUE',
  DRIVER_DOT_NUMBER_MISSING: 'VIR_DEFECT_DRIVER_DOT_NUMBER_MISSING',
  DRIVER_BODY_DAMAGE: 'VIR_DEFECT_DRIVER_BODY_DAMAGE_MORE_THAN_3INCHES',
  DRIVER_SHARP_EDGES_HOLES: 'VIR_DEFECT_DRIVER_SHARP_EDGES_HOLES',
  DRIVER_LOOSE_BODY_PARTS: 'VIR_DEFECT_DRIVER_LOOSE_BODY_PARTS',
  DRIVER_DOOR_FUNCTION_ISSUE: 'VIR_DEFECT_DRIVER_DOOR_FUNCTION_ISSUE',
  DRIVER_DOOR_LOCK_ISSUE: 'VIR_DEFECT_DRIVER_DOOR_LOCK_ISSUE',
  DRIVER_LOOSE_HANGING_OBJECTS_UNDERNEATH:
    'VIR_DEFECT_DRIVER_LOOSE_HANGING_OBJECTS_UNDERNEATH',
  DRIVER_NOTICEABLE_LEANING: 'VIR_DEFECT_DRIVER_NOTICEABLE_LEANING',
  DRIVER_MIRROR_ISSUES: 'VIR_DEFECT_DRIVER_MIRROR_ISSUES',
  DRIVER_MIRROR_ADJUSTMENT_ISSUE: 'VIR_DEFECT_DRIVER_MIRROR_ADJUSTMENT_ISSUE',
  DRIVER_MIRROR_HAZARD_INDICATOR_ISSUES:
    'VIR_DEFECT_DRIVER_MIRROR_HAZARD_INDICATOR_ISSUES',
  DRIVER_MIRROR_HAZARD_INDICATOR_NOT_WORKING:
    'VIR_DEFECT_DRIVER_MIRROR_HAZARD_INDICATOR_NOT_WORKING',
  DRIVER_BRAKE_PEDAL_ISSUE: 'VIR_DEFECT_DRIVER_BRAKE_PEDAL_ISSUE',
  DRIVER_EMERGENCY_BRAKE_ISSUE: 'VIR_DEFECT_DRIVER_EMERGENCY_BRAKE_ISSUE',
  DRIVER_HVAC_ISSUE: 'VIR_DEFECT_DRIVER_HVAC_ISSUE',
  DRIVER_HORN_ISSUE: 'VIR_DEFECT_DRIVER_HORN_ISSUE',
  DRIVER_STEERING_ISSUE: 'VIR_DEFECT_DRIVER_STEERING_ISSUE',
  DRIVER_CHECK_ENGINE_LIGHT_ON: 'VIR_DEFECT_DRIVER_CHECK_ENGINE_LIGHT_ON',
  DRIVER_LOW_OIL_LIFE: 'VIR_DEFECT_DRIVER_LOW_OIL_LIFE',
  DRIVER_TOLL_PASS_ISSUE: 'VIR_DEFECT_DRIVER_TOLL_PASS_ISSUE',
  DRIVER_SAMSARA_SYSTEM_ISSUE: 'VIR_DEFECT_DRIVER_SAMSARA_SYSTEM_ISSUE',
  DRIVER_VISOR_ISSUE: 'VIR_DEFECT_DRIVER_VISOR_ISSUE',
  DRIVER_MISSING_OSHA_ANSI_WARNING_TRIANGLES:
    'VIR_DEFECT_DRIVER_MISSING_OSHA_ANSI_WARNING_TRIANGLES',
  DRIVER_MISSING_FIRST_AID_KIT: 'VIR_DEFECT_DRIVER_MISSING_FIRST_AID_KIT',
  DRIVER_NON_APPROVED_DECALS: 'VIR_DEFECT_DRIVER_NON_APPROVED_DECALS',
  DRIVER_EXTERIOR_DIRTY: 'VIR_DEFECT_DRIVER_EXTERIOR_DIRTY',
  DRIVER_WRAP_DAMAGE: 'VIR_DEFECT_DRIVER_WRAP_DAMAGE',
  DRIVER_INTERIOR_GRIME: 'VIR_DEFECT_DRIVER_INTERIOR_GRIME',
  DRIVER_FRONT_FLAT_BUBBLING_BULGING_SWELLING:
    'VIR_DEFECT_DRIVER_FRONT_FLAT_BUBBLING_BULGING_SWELLING',
  DRIVER_FRONT_OBJECT_CUTS_DENTS_EXPOSED_WIRES:
    'VIR_DEFECT_DRIVER_FRONT_OBJECT_CUTS_DENTS_EXPOSED_WIRES',
  DRIVER_FRONT_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE:
    'VIR_DEFECT_DRIVER_FRONT_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE',
  DRIVER_FRONT_WHEEL_NUTS_ISSUE: 'VIR_DEFECT_DRIVER_FRONT_WHEEL_NUTS_ISSUE',
  DRIVER_FRONT_UNEVEN_WEAR_ON_TREADS:
    'VIR_DEFECT_DRIVER_FRONT_UNEVEN_WEAR_ON_TREADS',
  DRIVER_FRONT_INSUFFICIENT_TREAD: 'VIR_DEFECT_DRIVER_FRONT_INSUFFICIENT_TREAD',
  DRIVER_REAR_FLAT_BUBBLING_BULGING_SWELLING:
    'VIR_DEFECT_DRIVER_REAR_FLAT_BUBBLING_BULGING_SWELLING',
  DRIVER_REAR_OBJECT_CUTS_DENTS_EXPOSED_WIRES:
    'VIR_DEFECT_DRIVER_REAR_OBJECT_CUTS_DENTS_EXPOSED_WIRES',
  DRIVER_REAR_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE:
    'VIR_DEFECT_DRIVER_REAR_WHEEL_RIM_MOUNTING_EQUIPMENT_ISSUE',
  DRIVER_REAR_WHEEL_NUTS_ISSUE: 'VIR_DEFECT_DRIVER_REAR_WHEEL_NUTS_ISSUE',
  DRIVER_REAR_UNEVEN_WEAR_ON_TREADS:
    'VIR_DEFECT_DRIVER_REAR_UNEVEN_WEAR_ON_TREADS',
  DRIVER_REAR_INSUFFICIENT_TREAD: 'VIR_DEFECT_DRIVER_REAR_INSUFFICIENT_TREAD',
}

export const VIR_FREQUENCY = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
}
const VIR_IMAGE_DEFECT_TYPES = {
  ...VIR_DEFECT_FRONT,
  ...VIR_DEFECT_PASSENGER,
  ...VIR_DEFECT_REAR,
  ...VIR_DEFECT_DRIVER,
}
export const VIR_DAILY_PHOTOS_ARRAY = Object.values(VIR_DAILY_PHOTOS)
export const VIR_WEEKLY_PHOTOS_ARRAY = Object.values(VIR_WEEKLY_PHOTOS)
export const VIR_DEFECTS_PHOTOS_ARRAY = Object.values(VIR_IMAGE_DEFECT_TYPES)
